

html {
  scroll-behavior: smooth;
}



/* Import the font files */
@font-face {
  font-family: 'kanit-regular';
  src: url('../fonts/Kanit-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'kanit-semibold';
  src: url('../fonts/Kanit-SemiBold.ttf') format('truetype');
  font-weight: semibold;
  font-style: semibold;
}

@font-face {
  font-family: 'kanit-bold';
  src: url('../fonts/Kanit-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: bold;
}

/*
  //
  // DROPZONE
  //
*/

.dropzone .dz-preview.dz-image-preview {
    background: none !important;
}

.dropzone .dz-preview .dz-details,
.dropzone .dz-preview .dz-progress,
.dropzone .dz-preview .dz-error-message,
.dropzone .dz-preview .dz-success-mark,
.dropzone .dz-preview .dz-error-mark {
    display: none !important;
}

/*
  //
  // END DROPZONE
  //
*/


.sticky {

    position: sticky!important;
    top: 0!important;
  }


  .neon-headline {
        position: relative;
        text-align: center;


        text-shadow: 
              0 0 5px #0bf4f3,
              0 0 10px #0bf4f3,
              0 0 20px #0bf4f3,
              0 0 40px #0bf4f3,
              0 0 80px #0bf4f3;

  }



.neon-btn {
    font-size: .8rem;
    margin: auto;
    padding: 0.7rem;
    font-family: 'Kanit', sans-serif;
    text-decoration: none;
    text-shadow: -2px 2px 2px #D022ED;
    inset: 1px 1px 1px white;
    color: #fff;
    border: 2px solid;
    border-radius: 2rem;
    background-color: transparent;
    text-shadow:
        0 0 2px #D022ED,
        0 0 5px #D022ED,
        0 0 10px #D022ED,
        0 0 20px #D022ED;
    box-shadow:
        0 1px 2px rgba(0, 0, 0, 0.6),
        2px 1px 4px rgba(0, 0, 0, 0.3),
        2px 4px 3px rgba(3, 0, 128, 0.3),
        0 0 7px 2px rgba(208, 34, 237, 0.57),
        inset 0 1px 2px rgba(0, 0, 0, 0.6),
        inset 2px 1px 4px rgba(0, 0, 0, 0.3),
        inset 2px 4px 3px rgba(3, 0, 128, 0.3),
        inset 0 0 7px 2px rgba(208, 34, 237, 0.57);
    animation: flickering 5s infinite;
}

.neon-btn-no {
  font-size: .8rem;
    margin: auto;
padding: 0.7rem;
    font-family: 'Kanit', sans-serif;
    text-decoration: none;
    text-shadow: -2px 2px 2px #D022ED;
    inset: 1px 1px 1px white;
    color: #fff;
    border: 2px solid;
    border-color: #D022ED;
    border-radius: 2rem;
    background-color: transparent;
    text-shadow:
        0 0 2px #D022ED,
        0 0 5px #D022ED,
        0 0 10px #D022ED,
        0 0 20px #D022ED;
    box-shadow:
        0 1px 2px rgba(0, 0, 0, 0.6),
        2px 1px 4px rgba(0, 0, 0, 0.3),
        2px 4px 3px rgba(3, 0, 128, 0.3),
        0 0 7px 2px rgba(208, 34, 237, 0.57),
        inset 0 1px 2px rgba(0, 0, 0, 0.6),
        inset 2px 1px 4px rgba(0, 0, 0, 0.3),
        inset 2px 4px 3px rgba(3, 0, 128, 0.3),
        inset 0 0 7px 2px rgba(208, 34, 237, 0.57);
}


.neon-border {
    border: 2px solid;
    animation: flickering-slow 2s infinite;
    box-shadow:
        0 1px 2px rgba(0, 0, 0, 0.6),
        2px 1px 4px rgba(0, 0, 0, 0.3),
        2px 4px 3px rgba(3, 0, 128, 0.3),
        0 0 7px 2px rgba(208, 34, 237, 0.57),
        inset 0 1px 2px rgba(0, 0, 0, 0.6),
        inset 2px 1px 4px rgba(0, 0, 0, 0.3),
        inset 2px 4px 3px rgba(3, 0, 128, 0.3),
        inset 0 0 7px 2px rgba(208, 34, 237, 0.57);
}

.neon-border-no-flickering {
    border: 2px solid;
    border-color: #D022ED;
    box-shadow:
        0 1px 2px rgba(0, 0, 0, 0.6),
        2px 1px 4px rgba(0, 0, 0, 0.3),
        2px 4px 3px rgba(3, 0, 128, 0.3),
        0 0 7px 2px rgba(208, 34, 237, 0.57),
        inset 0 1px 2px rgba(0, 0, 0, 0.6),
        inset 2px 1px 4px rgba(0, 0, 0, 0.3),
        inset 2px 4px 3px rgba(3, 0, 128, 0.3),
        inset 0 0 7px 2px rgba(208, 34, 237, 0.57);
}

.neon-border-no-flickering-done {
    border: 2px solid;
    border-color: #39e75f;
    box-shadow:
        0 1px 2px rgba(0, 0, 0, 0.6),
        2px 1px 4px rgba(0, 0, 0, 0.3),
        2px 4px 3px rgba(3, 0, 128, 0.3),
        0 0 7px 2px rgba(208, 34, 237, 0.57),
        inset 0 1px 2px rgba(0, 0, 0, 0.6),
        inset 2px 1px 4px rgba(0, 0, 0, 0.3),
        inset 2px 4px 3px rgba(3, 0, 128, 0.3),
        inset 0 0 7px 2px rgba(208, 34, 237, 0.57);
}


.neon-btn:hover {

    text-shadow:
        -2px 2px 2px #D022ED;
    color: #D022ED;
    opacity: 50%;
    box-shadow:
        0 1px 2px rgba(0, 0, 0, 0.6),
        2px 1px 4px rgba(0, 0, 0, 0.3),
        2px 4px 3px rgba(3, 0, 128, 0.3),
        0 0 7px 2px rgba(206, 34, 237, 0.535),
        inset 0 1px 2px rgba(0, 0, 0, 0.6),
        inset 2px 1px 4px rgba(0, 0, 0, 0.3),
        inset 2px 4px 3px rgba(111, 0, 128, 0.3),
        inset 0 0 7px 2px rgba(208, 34, 237, 0.57);
}

.flickering-test {
    animation: flickering-test 4s infinite;
}


@keyframes flickering {
    0% {
        border-color: transparent;
        opacity: 0.2;
    }

    2% {
        border-color: #D022ED;
        ;
        opacity: 1;
    }

    4% {
        border-color: transparent;
        opacity: 0.2;
    }

    8% {
        border-color: #D022ED;
        ;
        opacity: 1;
    }

    28% {
        border-color: #D022ED;
        ;
        opacity: 1;
    }

    30% {
        border-color: transparent;
        opacity: 0.2;
    }

    36% {
        border-color: #D022ED;
        ;
        opacity: 1;
    }

    100% {
        border-color: #D022ED;
        ;
        opacity: 1;
    }
}


@keyframes flickering-slow {
    0% {
        border-color: transparent;
        opacity: 0.8;
    }

    2% {
        border-color: #D022ED;
        ;
        opacity: 0.5;
    }

    4% {
        border-color: transparent;
        opacity: 0.9;
    }

    8% {
        border-color: #D022ED;
        ;
        opacity: 1;
    }

    28% {
        border-color: #D022ED;
        ;
        opacity: 0.8;
    }

    30% {
        border-color: transparent;
        opacity: 0.9;
    }

    36% {
        border-color: #D022ED;
        ;
        opacity: 1;
    }

    100% {
        border-color: #D022ED;
        ;
        opacity: 0.5;
    }
}




.neon-text {
    color: #fff;
    text-shadow:
        0 0 2px #D022ED,
        0 0 10px #D022ED,
        0 0 20px #D022ED;
}

.neon-hover:hover {
    color: #fff;
    text-shadow:
        0 0 2px #D022ED,
        0 0 10px #D022ED,
        0 0 20px #D022ED,
        0 0 40px #D022ED,
        0 0 60px #D022ED,
        0 0 80px #D022ED;
}

.active {
    color: #fff;
    border-color: #D022ED;
    text-shadow:
        0 0 2px #D022ED,
        0 0 10px #D022ED,
        0 0 20px #D022ED,
        0 0 40px #D022ED,
        0 0 60px #D022ED,
        0 0 80px #D022ED;
}


.active-done {
    opacity: 70%;
    color: #fff;
    text-shadow:
        0 0 2px #4ade80,
        0 0 10px #4ade80,
        0 0 20px #4ade80,
        0 0 40px #4ade80,
        0 0 60px #4ade80,
        0 0 80px #4ade80;
}


.bg-card {
    background-color:#1e1b21;
}


/* Selection css */

.select {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-width: 300px;
    gap: 1px;
  }
  
  .select__item {
    padding: 10px;
    cursor: pointer;
    font-family: "Heebo", sans-serif;
    text-align: center;
    border-radius: 3px;
    background: #eeeeee;
    transition: background 0.1s;
  }
  
  .select__item--selected {
    background: #009578;
    color: #ffffff;
  }



  /* BTN TABS */

  .bn5 {
    padding: .6em 2em;
    shadow: white;
    border: none;
    outline: none;
    color: rgb(255, 255, 255);
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    font-color:#ffffff;

  }
  
  .bn5:before {
    content: "";
    background: linear-gradient(
      45deg,
      #D022ED,
      #D022ED,
      #fffb00,
      #D022ED,
      #00ffd5,
      #D022ED,
      #D022ED,
      #ff00c8,
      #D022ED
    );
    
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowingbn5 20s linear infinite;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
    font-color:#ffffff;
  }

    
  .bn5.active:before {
    opacity: .5;
  }
  
  @keyframes glowingbn5 {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
  
  .bn5:active {
    color: #000;
  }
  
  .bn5:active:after {
    background: transparent;
  }
  
  .bn5:hover:before {
    opacity: 1;
    color: white;
  }
  
  .bn5:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #191919;
    left: 0;
    top: 0;
    border-radius: 10px;
  }


  .gradient-text {
    position: relative;
    color: #ffffff;
  }
  
  .gradient-text::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(45deg, #D022ED, #D022ED, #fffb00, #D022ED, #00ffd5, #D022ED, #D022ED, #ff00c8, #D022ED);
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    animation: glowingbn5 20s linear infinite;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }
  
  .gradient-text:hover::before {
    opacity: 1;
  }













  /* BIG BN5 */

  .bn5-lg {
    padding: 1em 2em;
    border: 1px solid;
    border-color: #D022ED;
    outline: none;
    color: #ffffff;
    background: #D022ED;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    box-shadow: rgba(250, 252, 250, 0.1) -4px 9px 25px -6px;

  }
  
  .bn5-lg:before {
    content: "";
    background: linear-gradient(
      45deg,
      #D022ED,
      #D022ED,
      #fffb00,
      #D022ED,
      #00ffd5,
      #D022ED,
      #D022ED,
      #ff00c8,
      #D022ED
    );
    
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowingbn5 20s linear infinite;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
    font-color:#ffffff;
  }
  
  @keyframes glowingbn5-lg {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
  
  .bn5-lg:active {
    color: #000;
  }
  
  .bn5-lg:active:after {
    background: transparent;
  }
  
  .bn5-lg:hover:before {
    opacity: 1;
    color: white;
  }
  
  .bn5-lg:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #191919;
    left: 0;
    top: 0;
    border-radius: 10px;
  }

  .loader {
    transform: rotateZ(45deg);
    perspective: 1000px;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    color: #fff;
  }
    .loader:before,
    .loader:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: inherit;
      height: inherit;
      border-radius: 50%;
      transform: rotateX(70deg);
      animation: 1s spin linear infinite;
    }
    .loader:after {
      color: #D022ED;
      transform: rotateY(70deg);
      animation-delay: .4s;
    }

  @keyframes rotate {
    0% {
      transform: translate(-50%, -50%) rotateZ(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotateZ(360deg);
    }
  }

  @keyframes rotateccw {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(-360deg);
    }
  }

  @keyframes spin {
    0%,
    100% {
      box-shadow: .2em 0px 0 0px currentcolor;
    }
    12% {
      box-shadow: .2em .2em 0 0 currentcolor;
    }
    25% {
      box-shadow: 0 .2em 0 0px currentcolor;
    }
    37% {
      box-shadow: -.2em .2em 0 0 currentcolor;
    }
    50% {
      box-shadow: -.2em 0 0 0 currentcolor;
    }
    62% {
      box-shadow: -.2em -.2em 0 0 currentcolor;
    }
    75% {
      box-shadow: 0px -.2em 0 0 currentcolor;
    }
    87% {
      box-shadow: .2em -.2em 0 0 currentcolor;
    }
  }



/* Availability Indicator bottom BTN */


  .bn5-btm {
    padding: 1em 2em;
    border: none;
    outline: none;
    color: rgb(255, 255, 255);
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 0px;
    font-color:#ffffff;
    
    box-shadow: rgba(250, 252, 250, 0.1) -4px 9px 25px -6px;

  }
  
  .bn5-btm:before {
    content: "";
    background: linear-gradient(
      45deg,
      #D022ED,
      #D022ED,
      #fffb00,
      #D022ED,
      #00ffd5,
      #D022ED,
      #D022ED,
      #ff00c8,
      #D022ED
    );
    
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowingbn5 20s linear infinite;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
    font-color:#ffffff;
  }

  .bn5-btm.active:before {
    opacity: .9;
  }
  
  @keyframes glowingbn5-btm {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
  
  .bn5-btm:active {
    color: #000;
  }
  
  .bn5-btm:active:after {
    background: transparent;
  }
  
  .bn5-btm:hover:before {
    opacity: 1;
    color: white;
  }
  
  .bn5-btm:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #191919;
    left: 0;
    top: 0;
    border-radius: 10px;
  }



  .bn5-white {
    padding: .6em 2em;
    shadow: white;
    border: none;
    outline: none;
    color: #111;
    background: #fafafa;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    font-color:#111;

  }
  
