html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: kanit-regular;
  src: url("Kanit-Regular.6288591b.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: kanit-semibold;
  src: url("Kanit-SemiBold.99595f08.ttf") format("truetype");
  font-weight: semibold;
  font-style: semibold;
}

@font-face {
  font-family: kanit-bold;
  src: url("Kanit-Bold.d1949ef2.ttf") format("truetype");
  font-weight: bold;
  font-style: bold;
}

.dropzone .dz-preview.dz-image-preview {
  background: none !important;
}

.dropzone .dz-preview .dz-details, .dropzone .dz-preview .dz-progress, .dropzone .dz-preview .dz-error-message, .dropzone .dz-preview .dz-success-mark, .dropzone .dz-preview .dz-error-mark {
  display: none !important;
}

.sticky {
  position: sticky !important;
  top: 0 !important;
}

.neon-headline {
  text-align: center;
  text-shadow: 0 0 5px #0bf4f3, 0 0 10px #0bf4f3, 0 0 20px #0bf4f3, 0 0 40px #0bf4f3, 0 0 80px #0bf4f3;
  position: relative;
}

.neon-btn {
  text-shadow: -2px 2px 2px #d022ed;
  inset: 1px 1px 1px white;
  color: #fff;
  text-shadow: 0 0 2px #d022ed, 0 0 5px #d022ed, 0 0 10px #d022ed, 0 0 20px #d022ed;
  background-color: #0000;
  border: 2px solid;
  border-radius: 2rem;
  margin: auto;
  padding: .7rem;
  font-family: Kanit, sans-serif;
  font-size: .8rem;
  text-decoration: none;
  animation: 5s infinite flickering;
  box-shadow: 0 1px 2px #0009, 2px 1px 4px #0000004d, 2px 4px 3px #0300804d, 0 0 7px 2px #d022ed91, inset 0 1px 2px #0009, inset 2px 1px 4px #0000004d, inset 2px 4px 3px #0300804d, inset 0 0 7px 2px #d022ed91;
}

.neon-btn-no {
  text-shadow: -2px 2px 2px #d022ed;
  inset: 1px 1px 1px white;
  color: #fff;
  text-shadow: 0 0 2px #d022ed, 0 0 5px #d022ed, 0 0 10px #d022ed, 0 0 20px #d022ed;
  background-color: #0000;
  border: 2px solid #d022ed;
  border-radius: 2rem;
  margin: auto;
  padding: .7rem;
  font-family: Kanit, sans-serif;
  font-size: .8rem;
  text-decoration: none;
  box-shadow: 0 1px 2px #0009, 2px 1px 4px #0000004d, 2px 4px 3px #0300804d, 0 0 7px 2px #d022ed91, inset 0 1px 2px #0009, inset 2px 1px 4px #0000004d, inset 2px 4px 3px #0300804d, inset 0 0 7px 2px #d022ed91;
}

.neon-border {
  border: 2px solid;
  animation: 2s infinite flickering-slow;
  box-shadow: 0 1px 2px #0009, 2px 1px 4px #0000004d, 2px 4px 3px #0300804d, 0 0 7px 2px #d022ed91, inset 0 1px 2px #0009, inset 2px 1px 4px #0000004d, inset 2px 4px 3px #0300804d, inset 0 0 7px 2px #d022ed91;
}

.neon-border-no-flickering {
  border: 2px solid #d022ed;
  box-shadow: 0 1px 2px #0009, 2px 1px 4px #0000004d, 2px 4px 3px #0300804d, 0 0 7px 2px #d022ed91, inset 0 1px 2px #0009, inset 2px 1px 4px #0000004d, inset 2px 4px 3px #0300804d, inset 0 0 7px 2px #d022ed91;
}

.neon-border-no-flickering-done {
  border: 2px solid #39e75f;
  box-shadow: 0 1px 2px #0009, 2px 1px 4px #0000004d, 2px 4px 3px #0300804d, 0 0 7px 2px #d022ed91, inset 0 1px 2px #0009, inset 2px 1px 4px #0000004d, inset 2px 4px 3px #0300804d, inset 0 0 7px 2px #d022ed91;
}

.neon-btn:hover {
  text-shadow: -2px 2px 2px #d022ed;
  color: #d022ed;
  opacity: .5;
  box-shadow: 0 1px 2px #0009, 2px 1px 4px #0000004d, 2px 4px 3px #0300804d, 0 0 7px 2px #ce22ed88, inset 0 1px 2px #0009, inset 2px 1px 4px #0000004d, inset 2px 4px 3px #6f00804d, inset 0 0 7px 2px #d022ed91;
}

.flickering-test {
  animation: 4s infinite flickering-test;
}

@keyframes flickering {
  0% {
    opacity: .2;
    border-color: #0000;
  }

  2% {
    opacity: 1;
    border-color: #d022ed;
  }

  4% {
    opacity: .2;
    border-color: #0000;
  }

  8% {
    opacity: 1;
    border-color: #d022ed;
  }

  28% {
    opacity: 1;
    border-color: #d022ed;
  }

  30% {
    opacity: .2;
    border-color: #0000;
  }

  36% {
    opacity: 1;
    border-color: #d022ed;
  }

  100% {
    opacity: 1;
    border-color: #d022ed;
  }
}

@keyframes flickering-slow {
  0% {
    opacity: .8;
    border-color: #0000;
  }

  2% {
    opacity: .5;
    border-color: #d022ed;
  }

  4% {
    opacity: .9;
    border-color: #0000;
  }

  8% {
    opacity: 1;
    border-color: #d022ed;
  }

  28% {
    opacity: .8;
    border-color: #d022ed;
  }

  30% {
    opacity: .9;
    border-color: #0000;
  }

  36% {
    opacity: 1;
    border-color: #d022ed;
  }

  100% {
    opacity: .5;
    border-color: #d022ed;
  }
}

.neon-text {
  color: #fff;
  text-shadow: 0 0 2px #d022ed, 0 0 10px #d022ed, 0 0 20px #d022ed;
}

.neon-hover:hover {
  color: #fff;
  text-shadow: 0 0 2px #d022ed, 0 0 10px #d022ed, 0 0 20px #d022ed, 0 0 40px #d022ed, 0 0 60px #d022ed, 0 0 80px #d022ed;
}

.active {
  color: #fff;
  text-shadow: 0 0 2px #d022ed, 0 0 10px #d022ed, 0 0 20px #d022ed, 0 0 40px #d022ed, 0 0 60px #d022ed, 0 0 80px #d022ed;
  border-color: #d022ed;
}

.active-done {
  opacity: .7;
  color: #fff;
  text-shadow: 0 0 2px #4ade80, 0 0 10px #4ade80, 0 0 20px #4ade80, 0 0 40px #4ade80, 0 0 60px #4ade80, 0 0 80px #4ade80;
}

.bg-card {
  background-color: #1e1b21;
}

.select {
  max-width: 300px;
  grid-template-columns: repeat(3, 1fr);
  gap: 1px;
  display: grid;
}

.select__item {
  cursor: pointer;
  text-align: center;
  background: #eee;
  border-radius: 3px;
  padding: 10px;
  font-family: Heebo, sans-serif;
  transition: background .1s;
}

.select__item--selected {
  color: #fff;
  background: #009578;
}

.bn5 {
  shadow: white;
  color: #fff;
  cursor: pointer;
  z-index: 0;
  font-color: #fff;
  background: #111;
  border: none;
  border-radius: 10px;
  outline: none;
  padding: .6em 2em;
  position: relative;
}

.bn5:before {
  content: "";
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  opacity: 0;
  font-color: #fff;
  background: linear-gradient(45deg, #d022ed, #d022ed, #fffb00, #d022ed, #00ffd5, #d022ed, #d022ed, #ff00c8, #d022ed) 0 0 / 400%;
  border-radius: 10px;
  transition: opacity .3s ease-in-out;
  animation: 20s linear infinite glowingbn5;
  position: absolute;
  top: -2px;
  left: -2px;
}

.bn5.active:before {
  opacity: .5;
}

@keyframes glowingbn5 {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.bn5:active {
  color: #000;
}

.bn5:active:after {
  background: none;
}

.bn5:hover:before {
  opacity: 1;
  color: #fff;
}

.bn5:after {
  z-index: -1;
  content: "";
  width: 100%;
  height: 100%;
  background: #191919;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.gradient-text {
  color: #fff;
  position: relative;
}

.gradient-text:before {
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  filter: blur(5px);
  opacity: 0;
  background-image: linear-gradient(45deg, #d022ed, #d022ed, #fffb00, #d022ed, #00ffd5, #d022ed, #d022ed, #ff00c8, #d022ed);
  background-size: 400%;
  border-radius: 10px;
  transition: opacity .3s ease-in-out;
  animation: 20s linear infinite glowingbn5;
  position: absolute;
  top: 0;
  left: 0;
}

.gradient-text:hover:before {
  opacity: 1;
}

.bn5-lg {
  color: #fff;
  cursor: pointer;
  z-index: 0;
  background: #d022ed;
  border: 1px solid #d022ed;
  border-radius: 10px;
  outline: none;
  padding: 1em 2em;
  position: relative;
  box-shadow: -4px 9px 25px -6px #fafcfa1a;
}

.bn5-lg:before {
  content: "";
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  opacity: 0;
  font-color: #fff;
  background: linear-gradient(45deg, #d022ed, #d022ed, #fffb00, #d022ed, #00ffd5, #d022ed, #d022ed, #ff00c8, #d022ed) 0 0 / 400%;
  border-radius: 10px;
  transition: opacity .3s ease-in-out;
  animation: 20s linear infinite glowingbn5;
  position: absolute;
  top: -2px;
  left: -2px;
}

@keyframes glowingbn5-lg {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.bn5-lg:active {
  color: #000;
}

.bn5-lg:active:after {
  background: none;
}

.bn5-lg:hover:before {
  opacity: 1;
  color: #fff;
}

.bn5-lg:after {
  z-index: -1;
  content: "";
  width: 100%;
  height: 100%;
  background: #191919;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.loader {
  perspective: 1000px;
  width: 16px;
  height: 16px;
  color: #fff;
  border-radius: 50%;
  transform: rotateZ(45deg);
}

.loader:before, .loader:after {
  content: "";
  width: inherit;
  height: inherit;
  border-radius: 50%;
  animation: 1s linear infinite spin;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotateX(70deg);
}

.loader:after {
  color: #d022ed;
  animation-delay: .4s;
  transform: rotateY(70deg);
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%)rotateZ(0);
  }

  100% {
    transform: translate(-50%, -50%)rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%)rotate(0);
  }

  100% {
    transform: translate(-50%, -50%)rotate(-360deg);
  }
}

@keyframes spin {
  0%, 100% {
    box-shadow: .2em 0;
  }

  12% {
    box-shadow: .2em .2em;
  }

  25% {
    box-shadow: 0 .2em;
  }

  37% {
    box-shadow: -.2em .2em;
  }

  50% {
    box-shadow: -.2em 0;
  }

  62% {
    box-shadow: -.2em -.2em;
  }

  75% {
    box-shadow: 0 -.2em;
  }

  87% {
    box-shadow: .2em -.2em;
  }
}

.bn5-btm {
  color: #fff;
  cursor: pointer;
  z-index: 0;
  font-color: #fff;
  background: #111;
  border: none;
  border-radius: 0;
  outline: none;
  padding: 1em 2em;
  position: relative;
  box-shadow: -4px 9px 25px -6px #fafcfa1a;
}

.bn5-btm:before {
  content: "";
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  opacity: 0;
  font-color: #fff;
  background: linear-gradient(45deg, #d022ed, #d022ed, #fffb00, #d022ed, #00ffd5, #d022ed, #d022ed, #ff00c8, #d022ed) 0 0 / 400%;
  border-radius: 10px;
  transition: opacity .3s ease-in-out;
  animation: 20s linear infinite glowingbn5;
  position: absolute;
  top: -2px;
  left: -2px;
}

.bn5-btm.active:before {
  opacity: .9;
}

@keyframes glowingbn5-btm {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.bn5-btm:active {
  color: #000;
}

.bn5-btm:active:after {
  background: none;
}

.bn5-btm:hover:before {
  opacity: 1;
  color: #fff;
}

.bn5-btm:after {
  z-index: -1;
  content: "";
  width: 100%;
  height: 100%;
  background: #191919;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.bn5-white {
  shadow: white;
  color: #111;
  cursor: pointer;
  z-index: 0;
  font-color: #111;
  background: #fafafa;
  border: none;
  border-radius: 10px;
  outline: none;
  padding: .6em 2em;
  position: relative;
}

/*# sourceMappingURL=index.cd6cd959.css.map */
